import { Box, Container, TextField, Typography } from '@mui/material'
import { login_user } from 'api/clothes_service'
import { UserContext } from 'base'
import ButtonWithLoading from 'components/button_with_loading'
import { UnauthorisedError } from 'errors'
import { useDocumentTitle } from 'hooks'
import { useContext, useState } from 'react'
const Login = () => {
  useDocumentTitle('Login')

  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hasWrongCredentials, setHasWrongCredentials] = useState<boolean>(false)
  const [isServerDown, setIsServerDown] = useState<boolean>(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    try {
      await login_user(
        data.get('email') as string,
        data.get('password') as string
      )
    } catch (e: any) {
      if (e instanceof UnauthorisedError) {
        setHasWrongCredentials(true)
      }
      if (e instanceof Error && e.message.includes('Failed to fetch')) {
        setIsServerDown(true)
      }
      setIsLoading(false)
      return
    }

    await user.checkLoginStatus()
    setIsLoading(false)
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4">Thryft OMS 2.0</Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          error={hasWrongCredentials || isServerDown}
          onChange={() => {
            setHasWrongCredentials(false)
            setIsServerDown(false)
          }}
          disabled={isLoading}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={hasWrongCredentials || isServerDown}
          onChange={() => {
            setHasWrongCredentials(false)
            setIsServerDown(false)
          }}
          disabled={isLoading}
        />
        {hasWrongCredentials ? (
          <Typography variant="subtitle2" color="error">
            Wrong email and/or password
          </Typography>
        ) : null}
        {isServerDown ? (
          <Typography variant="subtitle2" color="error">
            Oops! The server is experiencing issues at the moment.
          </Typography>
        ) : null}
        <ButtonWithLoading
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          isLoading={isLoading}
        >
          Login
        </ButtonWithLoading>
      </Box>
    </Container>
  )
}

export default Login
