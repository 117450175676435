import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import '@fontsource/roboto'
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
} from '@mui/material'

import './index.css'
import { blueGrey } from '@mui/material/colors'
import TelegramMiniAppVintageBookCondition from 'telegram_mini_apps/vintage_book_condition'
import TelegramMiniAppVintageBookDetails from 'telegram_mini_apps/vintage_book_details'
import TelegramQrScannerMiniApp from 'telegram_mini_apps/telegram_qr_scanner'
import WebApp from 'web_app'

const telegramColourScheme = getComputedStyle(
  document.documentElement
).getPropertyValue('--tg-color-scheme')
const mode = ['light', 'dark'].includes(telegramColourScheme)
  ? (telegramColourScheme as PaletteMode)
  : undefined

const theme = createTheme({
  palette: {
    info: blueGrey,
    mode: mode,
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '1rem',
          },
        },
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/telegram/vintage-book/details"
            element={<TelegramMiniAppVintageBookDetails />}
          />
          <Route
            path="/telegram/vintage-book/condition"
            element={<TelegramMiniAppVintageBookCondition />}
          />
          <Route
            path="/telegram/qr-scanner"
            element={<TelegramQrScannerMiniApp />}
          />
          <Route path="*" element={<WebApp />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
