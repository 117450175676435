import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material'
import ButtonWithLoading from 'components/button_with_loading'
import { useDocumentTitle } from 'hooks'
import { useContext, useState } from 'react'
import { ExportToCsv } from 'export-to-csv-fix-source-map'
import { constructBoxWhjId } from 'models'
import { get_and_increment_next_box_whj_id } from 'api/clothes_service'
import { UnauthorisedError } from 'errors'
import { UserContext } from 'base'
import { formatIsoDate } from 'utils'

const BoxLabels = () => {
  useDocumentTitle('Download Box Labels')
  const user = useContext(UserContext)
  const [qty, setQty] = useState('0')
  const [boxType, setBoxType] = useState('Online Clothes')

  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const handleBoxType = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBoxType(event.target.value)
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h3">Download Box Labels</Typography>
          <Typography variant="subtitle1">
            Download box ID (BOX.WHJ.XXX) labels in CSV format for printing.
          </Typography>
        </Stack>
        <Box
          component="form"
          onSubmit={async (event) => {
            setIsGenerating(true)
            event.preventDefault()

            const numQty = Number(qty)
            let nextBoxId
            try {
              nextBoxId = await get_and_increment_next_box_whj_id(numQty)
            } catch (e: any) {
              if (e instanceof UnauthorisedError) {
                return user.setLoggedOut()
              }
              setIsGenerating(false)
              return
            }
            const data = []
            for (let i = 0; i < numQty; i++) {
              data.push({
                box_type: boxType,
                box_id: constructBoxWhjId(nextBoxId),
              })
              nextBoxId++
            }
            const isoDate = formatIsoDate(new Date())
            const csvExporter = new ExportToCsv({
              filename: `Thryft_Box_Labels_${isoDate}`,
              useKeysAsHeaders: true,
            })
            csvExporter.generateCsv(data)
            setIsGenerating(false)
          }}
        >
          <Stack spacing={3} alignItems="flex-start">
            <TextField
              required
              select
              value={boxType}
              label="Box Type"
              onChange={handleBoxType}
            >
              <MenuItem key="Online Clothes" value="Online Clothes">
                Online Clothes
              </MenuItem>
              <MenuItem key="Marketplace" value="Marketplace">
                Marketplace
              </MenuItem>
              <MenuItem key="Online Vinyl Records" value="Online Vinyl Records">
                Online Vinyl Records
              </MenuItem>
              <MenuItem key="Online CDs" value="Online CDs">
                Online CDs
              </MenuItem>
              <MenuItem key="Vintage Books" value="Vintage Books">
                Vintage Books
              </MenuItem>
            </TextField>
            <TextField
              required
              type="number"
              inputProps={{ min: 0 }}
              label={`No. of box labels`}
              value={qty}
              onChange={(event) => setQty(event.target.value)}
              error={Number(qty) === 1}
              helperText={Number(qty) === 1 ? 'Must be at least 2' : null}
            />

            <ButtonWithLoading
              type="submit"
              variant="contained"
              disabled={
                qty === '' || !Number.isInteger(Number(qty)) || Number(qty) <= 1
              }
              isLoading={isGenerating}
            >
              Download Labels
            </ButtonWithLoading>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default BoxLabels
