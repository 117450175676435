import { Container } from '@mui/material'

import './index.css'
import NewNavBar from 'components/new_nav_bar'
import AppRoutes from 'routes'
import { createContext, useCallback, useEffect, useState } from 'react'
import { check_is_supervisor_or_above } from 'api/clothes_service'
import { UserRole } from 'models'
import { UnauthorisedError } from 'errors'

export interface User {
  isLoggedIn: boolean | null
  isSupervisorOrAbove: boolean
  setLoggedOut: () => void
  checkLoginStatus: () => Promise<void>
}

export const UserContext = createContext<User>({
  isLoggedIn: null,
  isSupervisorOrAbove: false,
  setLoggedOut: () => {},
  checkLoginStatus: async () => {},
})

const Base = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)
  const [userRole, setUserRole] = useState<UserRole>(UserRole.STAFF)
  const isSupervisorOrAbove =
    userRole === UserRole.SUPERVISOR || userRole === UserRole.ADMIN
  const setLoggedOut = () => {
    setIsLoggedIn(false)
  }
  const checkLoginStatus = useCallback(async () => {
    try {
      const isSupervisor = await check_is_supervisor_or_above()
      setUserRole(isSupervisor ? UserRole.SUPERVISOR : UserRole.STAFF)
      setIsLoggedIn(true)
    } catch (e: any) {
      if (e instanceof UnauthorisedError) {
        setLoggedOut()
      }
      if (e instanceof Error && e.message.includes('Failed to fetch')) {
        setLoggedOut()
      }
    }
  }, [])

  const userContext = {
    isLoggedIn,
    isSupervisorOrAbove,
    setLoggedOut,
    checkLoginStatus,
  }

  useEffect(() => {
    if (isLoggedIn === null) {
      checkLoginStatus()
    }
  }, [checkLoginStatus, isLoggedIn])

  if (isLoggedIn === null) return null

  return (
    <UserContext.Provider value={userContext}>
      {/* <NavBar></NavBar> */}
      <NewNavBar />
      <Container sx={{ paddingBottom: '2rem' }}>
        <AppRoutes />
      </Container>
    </UserContext.Provider>
  )
}

export default Base
