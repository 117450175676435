import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import ButtonWithLoading from 'components/button_with_loading'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Typography } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddIcon from '@mui/icons-material/Add'
import MenuItem from '@mui/material/MenuItem'

// interface Data {
//   title: string
//   isbn?: string
//   publisher?: string
//   year?: string
// }

enum VintageBookGenre {
  ART = 'Art',
  BIOGRAPHIES_MEMOIRS = 'Biographies & Memoirs',
  ECONOMICS = 'Economics',
  FOOD_AND_DRINK = 'Food & Drink',
  GRAPHIC_NOVELS_COMICS_MANGA = 'Graphic Novels/Comics/Manga',
  HISTORY = 'History',
  LAW = 'Law',
  LITERATURE_LITERARY_CRITICISM = 'Literature & Literary Criticism',
  NATURE = 'Nature',
  PHILOSOPHY = 'Philosophy',
  POLITICS = 'Politics',
  RELIGION = 'Religion',
  SCIENCE = 'Science',
  SOCIOLOGY = 'Sociology',
  TRAVEL = 'Travel',
}

enum BookFormat {
  HARDCOVER = 'Hardcover',
  PAPERBACK = 'Paperback',
}

const getClearableInputProps = (
  value: string,
  setStateFn: (_: string) => void,
  isSelect: boolean = false
) => {
  return {
    endAdornment: (
      <InputAdornment
        position="end"
        // See https://github.com/mui/material-ui/issues/27141#issuecomment-2486355589
        sx={isSelect ? { position: 'absolute', right: 32 } : undefined}
      >
        <IconButton
          onClick={() => setStateFn('')}
          edge="end"
          aria-label="clear text"
          disabled={value === ''}
          size="small"
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    ),
  }
}

const isInteger = (value: string) => {
  const num = parseInt(value, 10)
  return Number.isInteger(num) && num.toString() === value
}

interface ListInputProps {
  list: string[]
  setList: React.Dispatch<React.SetStateAction<string[]>>
  heading: string
  textFieldLabel: string
  itemName: string
}
const ListInput = ({
  list,
  setList,
  heading,
  textFieldLabel,
  itemName,
}: ListInputProps) => {
  return (
    <>
      <Typography>{heading}</Typography>
      {list.map((value, index) => (
        <Stack direction="row" key={index}>
          <TextField
            required
            label={textFieldLabel}
            variant="outlined"
            value={value}
            fullWidth
            onChange={(event) => {
              setList((value) => {
                const newArray = [...value]
                newArray[index] = event.target.value
                return newArray
              })
            }}
          />
          <IconButton
            onClick={() =>
              setList((value) => {
                const newArray = [...value]
                newArray.splice(index, 1)
                return newArray
              })
            }
            aria-label={`Remove ${itemName}`}
          >
            <RemoveCircleIcon color="error" />
          </IconButton>
        </Stack>
      ))}
      <Button
        onClick={() => {
          setList((value) => [...value, ''])
        }}
        startIcon={<AddIcon />}
        sx={{ textTransform: 'none' }}
      >
        Add {itemName}
      </Button>
    </>
  )
}

const TelegramMiniAppVintageBookDetails = () => {
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const telegram = window.Telegram?.WebApp

  const [title, setTitle] = useState(searchParams.get('title') ?? '')
  const [isbn, setIsbn] = useState(searchParams.get('isbn') ?? '')
  const [publisher, setPublisher] = useState(
    searchParams.get('publisher') ?? ''
  )
  const [year, setYear] = useState(searchParams.get('year') ?? '')
  // TODO: When sending data, make sure empty strings are sent as null.
  const [edition, setEdition] = useState(searchParams.get('edition') ?? '')
  const [series, setSeries] = useState(searchParams.get('series') ?? '')

  const [authors, setAuthors] = useState<string[]>(
    searchParams.getAll('authors') ?? []
  )
  const [editors, setEditors] = useState<string[]>(
    searchParams.getAll('editors') ?? []
  )
  const [translators, setTranslators] = useState<string[]>(
    searchParams.getAll('translators') ?? []
  )
  const [illustrators, setIllustrators] = useState<string[]>(
    searchParams.getAll('illustrators') ?? []
  )

  const [genrePrimary, setGenrePrimary] = useState(
    searchParams.get('primary_genre') ?? ''
  )
  const [genreSecondary, setGenreSecondary] = useState(
    searchParams.get('secondary_genre') ?? ''
  )
  const [bookFormat, setBookFormat] = useState(searchParams.get('format') ?? '')

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const data = {
      title,
      isbn: isbn === '' ? null : isbn,
      publisher: publisher === '' ? null : publisher,
      year: year === '' ? null : Number(year),
      edition: edition === '' ? null : edition,
      series: series === '' ? null : series,
      authors,
      editors,
      translators,
      illustrators,
      primary_genre: genrePrimary,
      secondary_genre: genreSecondary === '' ? null : genreSecondary,
      format: bookFormat,
    }
    try {
      telegram.sendData(JSON.stringify(data))
    } catch (e) {
      if (!(e instanceof Error)) {
        return
      }
      if (e.message !== 'WebAppDataInvalid') {
        return
      }
      telegram.showAlert(
        'Oops! Please contact the Tech team for help (data is too long).'
      )
    }
  }

  // useEffect(() => {
  //   telegram.expand()
  //   telegram.MainButton.setText('Submit')
  //   telegram.MainButton.show()
  //   telegram.MainButton.disable()
  // }, [telegram])

  // TODO: Change to instant validation, allow unlimited string input.
  // TODO: Do validation for ISBN & year
  return (
    <Box padding={2}>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Stack rowGap={2}>
          <Typography variant="h6">
            Please verify the vintage book details:
          </Typography>

          {/* <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
          >
            Upload file
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                console.log(event.target.files)
              }
            />
          </Button> */}
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            required
          />
          <TextField
            label="ISBN"
            variant="outlined"
            value={isbn}
            onChange={(event) => setIsbn(event.target.value)}
            InputProps={getClearableInputProps(isbn, setIsbn)}
          />
          <TextField
            label="Publisher"
            variant="outlined"
            value={publisher}
            onChange={(event) => setPublisher(event.target.value)}
            InputProps={getClearableInputProps(publisher, setPublisher)}
          />
          <TextField
            label="Year"
            variant="outlined"
            error={!isInteger(year) && year !== ''}
            value={year}
            onChange={(event) => setYear(event.target.value)}
            InputProps={getClearableInputProps(year, setYear)}
            inputProps={{
              inputMode: 'numeric',
            }}
          />
          <TextField
            label="Edition"
            variant="outlined"
            value={edition}
            onChange={(event) => setEdition(event.target.value)}
            InputProps={getClearableInputProps(edition, setEdition)}
          />
          <TextField
            label="Series"
            variant="outlined"
            value={series}
            onChange={(event) => setSeries(event.target.value)}
            InputProps={getClearableInputProps(series, setSeries)}
          />
          <TextField
            required
            label="Genre (Primary)"
            select
            variant="outlined"
            value={genrePrimary}
            onChange={(event) => setGenrePrimary(event.target.value)}
          >
            {Object.values(VintageBookGenre).map((genre) => (
              <MenuItem key={genre} value={genre}>
                {genre}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Genre (Secondary)"
            select
            variant="outlined"
            value={genreSecondary}
            onChange={(event) => setGenreSecondary(event.target.value)}
            InputProps={getClearableInputProps(
              genreSecondary,
              setGenreSecondary,
              true
            )}
          >
            {Object.values(VintageBookGenre).map((genre) => (
              <MenuItem key={genre} value={genre}>
                {genre}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            label="Format"
            select
            variant="outlined"
            value={bookFormat}
            onChange={(event) => setBookFormat(event.target.value)}
          >
            {Object.values(BookFormat).map((format) => (
              <MenuItem key={format} value={format}>
                {format}
              </MenuItem>
            ))}
          </TextField>
          <ListInput
            list={authors}
            setList={setAuthors}
            heading="Authors"
            textFieldLabel="Author name"
            itemName="Author"
          />
          <ListInput
            list={editors}
            setList={setEditors}
            heading="Editors"
            textFieldLabel="Editor name"
            itemName="Editor"
          />
          <ListInput
            list={translators}
            setList={setTranslators}
            heading="Translators"
            textFieldLabel="Translator name"
            itemName="Translator"
          />
          <ListInput
            list={illustrators}
            setList={setIllustrators}
            heading="Illustrators"
            textFieldLabel="Illustrator name"
            itemName="Illustrator"
          />
          <ButtonWithLoading
            type="submit"
            fullWidth
            variant="contained"
            isLoading={isLoading}
          >
            Submit
          </ButtonWithLoading>
        </Stack>
      </Box>
    </Box>
  )
}

export default TelegramMiniAppVintageBookDetails
