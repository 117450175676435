import { useContext } from 'react'
import { AppBar, Tab, Tabs, Toolbar } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { UserContext } from 'base'
const NewNavBar = () => {
  // Reference: https://stackoverflow.com/questions/74556799/how-to-highlight-and-get-active-state-for-main-navigation-menu-item-and-sub-navi
  const { pathname } = useLocation()
  const base = `/${pathname.slice(1).split('/').shift()}`
  const user = useContext(UserContext)
  const links = [
    { to: '/', label: 'Home', supervisorRequired: false },
    {
      to: '/clothing-labels',
      label: 'Clothing Labels',
      supervisorRequired: false,
    },
    { to: '/sku-labels', label: 'SKU Labels', supervisorRequired: false },
    { to: '/box-labels', label: 'Box Labels', supervisorRequired: false },
    { to: '/brands', label: 'Brands', supervisorRequired: true },
    { to: '/in-progress', label: 'In Progress', supervisorRequired: true },
    { to: '/partial', label: 'Partial Uploads', supervisorRequired: true },
    { to: '/uploads', label: 'Uploads For Review', supervisorRequired: true },
    { to: '/completed', label: 'Completed', supervisorRequired: true },
    { to: '/vintage-books', label: 'Vintage Books', supervisorRequired: true },
    { to: '/logout', label: 'Logout', supervisorRequired: false },
  ]

  return (
    <>
      <AppBar position="fixed" sx={{ top: 'auto' }}>
        <Tabs
          value={base}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {user.isLoggedIn ? (
            links.map((link) =>
              user.isSupervisorOrAbove || !link.supervisorRequired ? (
                <Tab
                  component={Link}
                  to={link.to}
                  label={link.label}
                  key={link.to}
                  value={link.to}
                />
              ) : null
            )
          ) : (
            <Tab
              component={Link}
              to="/login"
              label="Login"
              key="/login"
              value="/login"
            />
          )}
        </Tabs>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default NewNavBar
