import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ButtonWithLoading from 'components/button_with_loading'
import { useId, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

enum YesNo {
  YES = 'Yes',
  NO = 'No',
}

enum BindingOrInsertCondition {
  INTACT = 'Intact',
  SLIGHTLY_LOOSE = 'Slightly loose',
  LOOSE = 'Loose',
}

enum Foxing {
  CLEAN = 'Clean of foxing',
  SLIGHT = 'Slight',
  SOME = 'Some',
  MODERATE = 'Moderate',
  HEAVY = 'Heavy',
}

enum Annotations {
  CLEAN = 'Clean of annotations',
  SLIGHT = 'Slight',
  SOME = 'Some',
  MODERATE = 'Moderate',
  HEAVY = 'Heavy',
}

enum Tanning {
  NONE = 'Not at all',
  SLIGHT = 'Slight',
  SOME = 'Some',
  MODERATE = 'Moderate',
  HEAVY = 'Heavy',
}

enum Gilt {
  NONE = 'No gilt',
  NOT_TARNISHED = 'Gilt present and not tarnished',
  SLIGHTLY_TARNISHED = 'Gilt present but slightly tarnished',
  HEAVILY_TARNISHED = 'Gilt present but heavily tarnished',
}

enum WearAndTear {
  TEAR = 'Tear(s)',
  CREASE = 'Crease(s)',
  STAIN = 'Stain(s)',
  SCRATCH = 'Scratche(s)',
  FRAYING = 'Fraying of edges',
  STICKER_TAPE_SHADOW = 'Sticker / tape shadow(s)',
}

enum SpineTear {
  NO_TEAR = 'No tears',
  TEAR_TOP = 'Tear in top of spine',
  TEAR_BOTTOM = 'Tear in bottom of spine',
}

enum Fading {
  NONE = 'Not at all',
  SOME = 'Some',
  SEVERE = 'Severe',
}

enum PageFrequency {
  CERTAIN = 'On certain pages',
  SOME = 'On some pages',
  MOST = 'On most pages',
  ACROSS = 'Across pages',
}

interface RequiredField {
  value: string
  setValue: (_: string) => void
  ref: React.MutableRefObject<HTMLDivElement | null>
  isEmpty: boolean
}

const useRequiredField = (
  initialValue: string,
  onSetValue?: (value: string) => void
): RequiredField => {
  const [value, setValue] = useState(initialValue)
  const ref = useRef<null | HTMLDivElement>(null)

  // Note: If only want to show error if value has been modified at least once,
  // use isModified, and setIsModified when the value is changed.
  // Note: If only want to show error after attempting to submit,
  // use hasSubmitted, and let the parent setHasSubmitted.

  const _setValue = (newValue: string) => {
    if (onSetValue !== undefined) {
      onSetValue(newValue)
    }
    setValue(newValue)
  }

  return {
    value,
    setValue: _setValue,
    ref,
    // TODO: For isEmpty check
    isEmpty: value === '',
  }
}

interface Field<T> {
  value: T
  setValue: React.Dispatch<React.SetStateAction<T>>
  ref: React.MutableRefObject<HTMLDivElement | null>
  isEmpty: boolean
}

const useField = <T,>(
  initialValue: T,
  isEmpty: (value: T) => boolean
): Field<T> => {
  const [value, setValue] = useState(initialValue)
  const ref = useRef<null | HTMLDivElement>(null)

  // Note: If only want to show error if value has been modified at least once,
  // use isModified, and setIsModified when the value is changed.
  // Note: If only want to show error after attempting to submit,
  // use hasSubmitted, and let the parent setHasSubmitted.

  return {
    value,
    setValue,
    ref,
    isEmpty: isEmpty(value),
  }
}

interface MCQFieldProps {
  field: RequiredField
  label: string
  options: { [s: number | string]: string }
}

const MCQField = ({ field, label, options }: MCQFieldProps) => {
  const labelId = useId()
  return (
    <FormControl required ref={field.ref} error={field.isEmpty}>
      <FormLabel id={labelId}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        value={field.value}
        onChange={(event) => field.setValue(event.target.value)}
      >
        {Object.values(options).map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={value}
          />
        ))}
      </RadioGroup>
      {field.isEmpty && (
        <FormHelperText sx={{ mx: 0 }}>
          This is a required question
        </FormHelperText>
      )}
    </FormControl>
  )
}

const validateEnum = (
  enumeration: { [s: number | string]: string },
  value: string | null
) => {
  if (value === null) {
    return ''
  }
  return Object.values<string>(enumeration).includes(value) ? value : ''
}

const validateBool = (value: string | null) => {
  if (value === 'true') return true
  if (value === 'false') return false
  return null
}

const returnNullIfEmpty = (value: string | null | undefined): string | null => {
  return value && value.trim() !== '' ? value : null
}

const TelegramMiniAppVintageBookCondition = () => {
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const telegram = window.Telegram?.WebApp

  const isExLibrary = validateBool(searchParams.get('is_ex_library'))
  const fieldExLibrary = useRequiredField(
    isExLibrary === null ? '' : isExLibrary ? YesNo.YES : YesNo.NO
  )

  const hasBookplate = validateBool(searchParams.get('has_bookplate'))
  const fieldBookplate = useRequiredField(
    hasBookplate === null ? '' : hasBookplate ? YesNo.YES : YesNo.NO
  )

  const fieldBindingCondition = useRequiredField(
    validateEnum(
      BindingOrInsertCondition,
      searchParams.get('binding_condition')
    )
  )
  const fieldFrontCoverFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('front_cover_foxing'))
  )

  const fieldFrontCoverGilt = useRequiredField(
    validateEnum(Gilt, searchParams.get('front_cover_gilt'))
  )

  const fieldBackCoverFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('back_cover_foxing'))
  )
  const fieldBackCoverWearAndTear = useField(
    new Set(searchParams.getAll('back_cover_wear_and_tear')),
    (value) => value.size === 0
  )

  const fieldSpineFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('spine_foxing'))
  )
  const fieldSpineTear = useRequiredField(
    validateEnum(SpineTear, searchParams.get('spine_tears'))
  )
  const fieldSpineFading = useRequiredField(
    validateEnum(Fading, searchParams.get('spine_fading'))
  )

  const fieldSpineGilt = useRequiredField(
    validateEnum(Gilt, searchParams.get('spine_gilt'))
  )

  const fieldTopEdgeTanning = useRequiredField(
    validateEnum(Tanning, searchParams.get('top_edge_tanning'))
  )
  const fieldTopEdgeFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('top_edge_foxing'))
  )

  const fieldForeEdgeTanning = useRequiredField(
    validateEnum(Tanning, searchParams.get('fore_edge_tanning'))
  )
  const fieldForeEdgeFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('fore_edge_foxing'))
  )

  const fieldBottomEdgeTanning = useRequiredField(
    validateEnum(Tanning, searchParams.get('bottom_edge_tanning'))
  )
  const fieldBottomEdgeFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('bottom_edge_foxing'))
  )

  const fieldInsideCoverFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('inside_cover_foxing'))
  )

  const fieldPageFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('page_foxing')),
    (value) => {
      if (value === Foxing.CLEAN) {
        fieldPageFoxingFrequency.setValue(PageFrequency.ACROSS)
      }
    }
  )

  const fieldPageFoxingFrequency = useRequiredField(
    validateEnum(PageFrequency, searchParams.get('page_foxing_frequency')),
    (value) => {
      if (
        fieldPageFoxing.value === Foxing.CLEAN &&
        value !== PageFrequency.ACROSS
      ) {
        fieldPageFoxing.setValue('')
      }
    }
  )

  const fieldPageAnnotations = useRequiredField(
    validateEnum(Annotations, searchParams.get('page_annotations')),
    (value) => {
      if (value === Annotations.CLEAN) {
        fieldPageAnnotationFrequency.setValue(PageFrequency.ACROSS)
      }
    }
  )

  const fieldPageAnnotationFrequency = useRequiredField(
    validateEnum(PageFrequency, searchParams.get('page_annotation_frequency')),
    (value) => {
      if (
        fieldPageAnnotations.value === Annotations.CLEAN &&
        value !== PageFrequency.ACROSS
      ) {
        fieldPageAnnotations.setValue('')
      }
    }
  )
  const fieldPageMarginTanning = useRequiredField(
    validateEnum(Tanning, searchParams.get('page_margin_tanning'))
  )
  const fieldInserts = useRequiredField(
    Array.from(searchParams.keys()).some((key: string) =>
      key.startsWith('inserts.')
    )
      ? YesNo.YES
      : ''
  )
  const fieldInsertsCondition = useRequiredField(
    validateEnum(
      BindingOrInsertCondition,
      searchParams.get('inserts.condition')
    )
  )
  const fieldInsertsFoxing = useRequiredField(
    validateEnum(Foxing, searchParams.get('inserts.foxing'))
  )

  const [frontCoverWearAndTear, setFrontCoverWearAndTear] = useState(
    new Set(searchParams.getAll('front_cover_wear_and_tear'))
  )

  const [frontCoverAnnotations, setFrontCoverAnnotations] = useState(
    searchParams.get('front_cover_annotations') ?? ''
  )
  const [backCoverAnnotations, setBackCoverAnnotations] = useState(
    searchParams.get('back_cover_annotations') ?? ''
  )

  const [insideCoverAnnotations, setInsideCoverAnnotations] = useState(
    searchParams.get('inside_cover_annotations') ?? ''
  )

  const [pageTear, setPageTear] = useState(searchParams.get('page_tears') ?? '')

  const [insertsTear, setInsertsTear] = useState(
    searchParams.get('inserts.tears') ?? ''
  )

  const [others, setOthers] = useState(searchParams.get('others') ?? '')

  const requiredFieldsOrdered = [
    fieldExLibrary,
    fieldBindingCondition,
    fieldFrontCoverFoxing,
    fieldFrontCoverGilt,
    fieldBackCoverFoxing,
    fieldSpineFoxing,
    fieldSpineTear,
    fieldSpineFading,
    fieldSpineGilt,
    fieldTopEdgeTanning,
    fieldTopEdgeFoxing,
    fieldForeEdgeTanning,
    fieldForeEdgeFoxing,
    fieldBottomEdgeTanning,
    fieldBottomEdgeFoxing,
    fieldBookplate,
    fieldInsideCoverFoxing,
    fieldPageFoxing,
    fieldPageFoxingFrequency,
    fieldPageAnnotations,
    fieldPageAnnotationFrequency,
    fieldPageMarginTanning,
    fieldInserts,
    ...(fieldInserts.value === YesNo.YES
      ? [fieldInsertsCondition, fieldInsertsFoxing]
      : []),
  ]

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    let hasError = false

    requiredFieldsOrdered.forEach((field) => {
      if (!field.isEmpty) {
        return
      }
      if (hasError) {
        return
      }
      field.ref.current?.scrollIntoView()
      hasError = true
    })

    if (!hasError) {
      const data = {
        is_ex_library: fieldExLibrary.value === YesNo.YES,
        binding_condition: fieldBindingCondition.value,
        front_cover_foxing: fieldFrontCoverFoxing.value,
        front_cover_wear_and_tear: Array.from(frontCoverWearAndTear),
        front_cover_annotations: returnNullIfEmpty(frontCoverAnnotations),
        front_cover_gilt: fieldFrontCoverGilt.value,
        back_cover_foxing: fieldBackCoverFoxing.value,
        back_cover_wear_and_tear: Array.from(fieldBackCoverWearAndTear.value),
        back_cover_annotations: returnNullIfEmpty(backCoverAnnotations),
        spine_foxing: fieldSpineFoxing.value,
        spine_tears: fieldSpineTear.value,
        spine_fading: fieldSpineFading.value,
        spine_gilt: fieldSpineGilt.value,
        top_edge_tanning: fieldTopEdgeTanning.value,
        top_edge_foxing: fieldTopEdgeFoxing.value,
        fore_edge_tanning: fieldForeEdgeTanning.value,
        fore_edge_foxing: fieldForeEdgeFoxing.value,
        bottom_edge_tanning: fieldBottomEdgeTanning.value,
        bottom_edge_foxing: fieldBottomEdgeFoxing.value,
        has_bookplate: fieldBookplate.value === YesNo.YES,
        inside_cover_foxing: fieldInsideCoverFoxing.value,
        inside_cover_annotations: returnNullIfEmpty(insideCoverAnnotations),
        page_foxing: fieldPageFoxing.value,
        page_foxing_frequency: fieldPageFoxingFrequency.value,
        page_annotations: fieldPageAnnotations.value,
        page_annotation_frequency: fieldPageAnnotationFrequency.value,
        page_margin_tanning: fieldPageMarginTanning.value,
        page_tears: returnNullIfEmpty(pageTear),
        inserts:
          fieldInserts.value === YesNo.YES
            ? {
                condition: fieldInsertsCondition.value,
                foxing: fieldInsertsFoxing.value,
                tears: returnNullIfEmpty(insertsTear),
              }
            : null,
        others: returnNullIfEmpty(others),
      }
      console.log(data)
      console.log(JSON.stringify(data))
      telegram.sendData(JSON.stringify(data))
    }

    setIsLoading(false)
  }

  return (
    <Box padding={2}>
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Stack rowGap={2}>
          <Typography variant="h5">Vintage Book Condition</Typography>
          <Typography variant="subtitle2">
            Please verify and fill up the form:
          </Typography>

          <MCQField
            field={fieldExLibrary}
            label={'Ex library?'}
            options={YesNo}
          />

          <Typography variant="h5">Binding</Typography>
          <MCQField
            field={fieldBindingCondition}
            label={'Condition of binding'}
            options={BindingOrInsertCondition}
          />

          <Typography variant="h5">Front Cover</Typography>

          <MCQField
            field={fieldFrontCoverFoxing}
            label={'Foxing on front cover'}
            options={Foxing}
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Wear and tear on front cover
            </FormLabel>
            <FormGroup>
              {Object.values(WearAndTear).map((value) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={frontCoverWearAndTear.has(value)}
                      onChange={(event) => {
                        setFrontCoverWearAndTear((prevSet) => {
                          if (event.target.checked) {
                            prevSet.add(value)
                          } else {
                            prevSet.delete(value)
                          }
                          return new Set(prevSet)
                        })
                      }}
                      name={value}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </FormControl>

          <TextField
            label="Annotation(s) on front cover?"
            variant="outlined"
            value={frontCoverAnnotations}
            onChange={(event) => setFrontCoverAnnotations(event.target.value)}
            helperText="E.g. 'Line in ink'"
          />

          <MCQField
            field={fieldFrontCoverGilt}
            label={'Gilt on front cover'}
            options={Gilt}
          />

          <Typography variant="h5">Back Cover</Typography>

          <MCQField
            field={fieldBackCoverFoxing}
            label={'Foxing on back cover'}
            options={Foxing}
          />

          <FormControl component="fieldset">
            <FormLabel component="legend">
              Wear and tear on back cover
            </FormLabel>
            <FormGroup>
              {Object.values(WearAndTear).map((value) => (
                <FormControlLabel
                  key={value}
                  control={
                    <Checkbox
                      checked={fieldBackCoverWearAndTear.value.has(value)}
                      onChange={(event) => {
                        fieldBackCoverWearAndTear.setValue((prevSet) => {
                          if (event.target.checked) {
                            prevSet.add(value)
                          } else {
                            prevSet.delete(value)
                          }
                          return new Set(prevSet)
                        })
                      }}
                      name={value}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </FormControl>

          <TextField
            label="Annotation(s) on back cover?"
            variant="outlined"
            value={backCoverAnnotations}
            onChange={(event) => setBackCoverAnnotations(event.target.value)}
            helperText="E.g. 'Line in ink'"
          />

          <Typography variant="h5">Spine of Book</Typography>

          <MCQField
            field={fieldSpineFoxing}
            label={'Foxing on spine'}
            options={Foxing}
          />

          <MCQField
            field={fieldSpineTear}
            label={'Tear(s) on spine'}
            options={SpineTear}
          />

          <MCQField
            field={fieldSpineFading}
            label={'Fading on spine'}
            options={Fading}
          />

          <MCQField
            field={fieldSpineGilt}
            label={'Gilt on spine'}
            options={Gilt}
          />

          <Typography variant="h5">Top Edge of Book</Typography>

          <MCQField
            field={fieldTopEdgeTanning}
            label={'Tanning on top edge of book'}
            options={Tanning}
          />

          <MCQField
            field={fieldTopEdgeFoxing}
            label={'Foxing on top edge of book'}
            options={Foxing}
          />

          <Typography variant="h5">Fore Edge of Book</Typography>

          <MCQField
            field={fieldForeEdgeTanning}
            label={'Tanning on fore edge of book'}
            options={Tanning}
          />

          <MCQField
            field={fieldForeEdgeFoxing}
            label={'Foxing on fore edge of book'}
            options={Foxing}
          />

          <Typography variant="h5">Bottom Edge of Book</Typography>

          <MCQField
            field={fieldBottomEdgeTanning}
            label={'Tanning on bottom edge of book'}
            options={Tanning}
          />

          <MCQField
            field={fieldBottomEdgeFoxing}
            label={'Foxing on bottom edge of book'}
            options={Foxing}
          />

          <Typography variant="h5">Insides of Covers / Endpapers</Typography>

          <MCQField
            field={fieldBookplate}
            label={'Bookplate?'}
            options={YesNo}
          />

          <MCQField
            field={fieldInsideCoverFoxing}
            label={'Foxing on insides of covers / endpapers'}
            options={Foxing}
          />

          <TextField
            label="Annotation(s) on insides of covers?"
            variant="outlined"
            value={insideCoverAnnotations}
            onChange={(event) => setInsideCoverAnnotations(event.target.value)}
            helperText="E.g. 'Price in pencil'"
          />

          <Typography variant="h5">Pages of Book</Typography>

          <MCQField
            field={fieldPageFoxing}
            label={'Degree of foxing on page'}
            options={Foxing}
          />

          <MCQField
            field={fieldPageFoxingFrequency}
            label={'Percentage across pages (refer to prev qn)'}
            options={PageFrequency}
          />

          <MCQField
            field={fieldPageAnnotations}
            label={'Degree of annotations on page'}
            options={Annotations}
          />

          <MCQField
            field={fieldPageAnnotationFrequency}
            label={'Percentage across pages (refer to prev qn)'}
            options={PageFrequency}
          />

          <MCQField
            field={fieldPageMarginTanning}
            label={'Tanning in margins of pages'}
            options={Tanning}
          />

          <TextField
            label="Tear(s) in the pages of the book, if any"
            variant="outlined"
            value={pageTear}
            onChange={(event) => setPageTear(event.target.value)}
            helperText="E.g. 'Dog ears', 'Slight tears in a few pages'"
          />

          <Typography variant="h5">Inserts</Typography>

          <MCQField
            field={fieldInserts}
            label={'Are there inserts?'}
            options={YesNo}
          />

          {fieldInserts.value === YesNo.YES && (
            <>
              <MCQField
                field={fieldInsertsCondition}
                label={'Condition of inserts'}
                options={BindingOrInsertCondition}
              />
              <MCQField
                field={fieldInsertsFoxing}
                label={'Foxing on inserts'}
                options={Foxing}
              />
              <TextField
                label="Tear(s) in the inserts, if any"
                variant="outlined"
                value={insertsTear}
                onChange={(event) => setInsertsTear(event.target.value)}
                helperText="E.g. 'Dog ears', 'Slight tears in a few pages'"
              />
            </>
          )}

          <Typography variant="h5">Others</Typography>

          <TextField
            label="Anything else to note (e.g. any other defects)"
            variant="outlined"
            value={others}
            onChange={(event) => setOthers(event.target.value)}
          />

          <ButtonWithLoading
            type="submit"
            fullWidth
            variant="contained"
            isLoading={isLoading}
          >
            Submit
          </ButtonWithLoading>
        </Stack>
      </Box>
    </Box>
  )
}
export default TelegramMiniAppVintageBookCondition
