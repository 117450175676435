import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom'
import '@fontsource/roboto'

import './index.css'
import Login from 'routes/login'
import Home from 'routes/home'
import Uploads from 'routes/uploads'
import Upload from 'routes/upload'
import ClothingLabels from 'routes/clothing_labels'
import BrandTiers from 'routes/brand_tiers'
import { UploadStatus } from 'models'
import Logout from 'routes/logout'
import ChangePassword from 'routes/change_password'
import CompletedUploads from 'routes/completed_uploads'
import { useContext } from 'react'
import { UserContext } from 'base'
import BoxLabels from 'routes/box_labels'
import SkuLabels from 'routes/sku_labels'
import VintageBooks from 'routes/vintage_books'

const PrivateOutlet = ({
  shouldRedirectToPage = true,
}: {
  shouldRedirectToPage?: boolean
}) => {
  const location = useLocation()
  const user = useContext(UserContext)
  if (user.isLoggedIn) {
    return <Outlet />
  }

  return (
    // TODO: Redirect via search param instead of state.
    <Navigate
      to="/login"
      state={shouldRedirectToPage ? { from: location.pathname } : undefined}
    />
  )
}

const SupervisorRequired = () => {
  const user = useContext(UserContext)
  return user.isSupervisorOrAbove ? <Outlet /> : <Navigate to="/" />
}

const AlreadyLoggedIn = () => {
  const user = useContext(UserContext)
  const location = useLocation()

  return user.isLoggedIn ? (
    <Navigate to={location.state?.from ?? '/'} />
  ) : (
    <Outlet />
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AlreadyLoggedIn />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<PrivateOutlet shouldRedirectToPage={false} />}>
        <Route path="/logout" element={<Logout />} />
      </Route>
      <Route element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route element={<SupervisorRequired />}>
          <Route path="/brands" element={<BrandTiers />} />
          <Route
            path="/in-progress"
            element={<Uploads uploadStatus={UploadStatus.IN_PROGRESS} />}
          />
          <Route
            path="/in-progress/:id"
            element={<Upload uploadStatus={UploadStatus.IN_PROGRESS} />}
          />
          <Route
            path="/partial"
            element={<Uploads uploadStatus={UploadStatus.PARTIAL} />}
          />
          <Route
            path="/partial/:id"
            element={<Upload uploadStatus={UploadStatus.PARTIAL} />}
          />
          <Route
            path="/uploads"
            element={<Uploads uploadStatus={UploadStatus.IN_REVIEW} />}
          />
          <Route
            path="/uploads/:id"
            element={<Upload uploadStatus={UploadStatus.IN_REVIEW} />}
          />
          <Route path="/completed" element={<CompletedUploads />} />
          <Route
            path="/completed/:id"
            element={<Upload uploadStatus={UploadStatus.COMPLETED} />}
          />
          <Route path="/vintage-books" element={<VintageBooks />} />
        </Route>
        <Route path="/clothing-labels" element={<ClothingLabels />} />
        <Route path="/box-labels" element={<BoxLabels />} />
        <Route path="/sku-labels" element={<SkuLabels />} />
        <Route path="*" element={<>Page Not Found</>} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
